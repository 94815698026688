Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.streamingApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Videos";
exports.labelBodyText = "Videos Body";
exports.exampleVideoURL =
  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";
exports.btnExampleTitle = "CLICK ME";
exports.getStreamingUrl =
  "bx_block_content_management/contents/get_streaming_url";
exports.postWatchHistory =
  "bx_block_content_management/contents/create_history";
exports.getEpisodesBySeason =
  "bx_block_content_management/episodes/get_episode";
exports.getWebSeries = "bx_block_content_management/episodes/web_series";
exports.getMovieDetail = "bx_block_content_management/contents";
exports.getEpisodeByID = "bx_block_content_management/episodes";
exports.getCurrentPlan = "bx_block_plan/plans/current_plan";
exports.staticStreamingurl ="https://ep-default-vod-mediakind.eastus.streaming.mediakind.com/5bfe2baa-5975-4052-b5e4-86f36f45a802/manifest.ism/manifest(format=mpd-time-cmaf,encryption=cenc)";
exports.mediaKindHeader = "Bearer=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzZXJnaW9jbyIsImF1ZCI6ImF1ZGllbmNlIn0.F90PuJchYj0s93LZACCxtBPzkix61ger5qDZULBg5gY";
exports.mediaKindToken = "https://ottapp-appgw-amp.prodc.mkio.tv3cloud.com/drm/widevine-license?ownerUid=azuki"
exports.getMKStreamingUrl = "bx_block_content_management/contents/get_mk_streaming_url?assetId=";
// Customizable Area End