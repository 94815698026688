import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config.js");
import { sendAPIRequest } from "../../../components/src/Utility";
import { toast } from 'react-toastify';
import CONTENT_TYPES from "../../../components/src/Enums";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    headerText: any;
    t: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string;
    errorMsg: string;
    imgIndex: any;
    loading: boolean;
    similarMovies: any;
    contentDetails: any;
    contentId: any;
    playVideo: boolean;
    streamingURL: string;
    userCountError: boolean;
    assetID: string,
    seasonsData: any,
    isSubscribed: boolean,
    updatePlayer: boolean,
    title: string,
    playingSeason: any,
    seasonsFetched: boolean,
    isFetchWebSeries: boolean,
    playFromStart: boolean,
    updatePodcastParts: boolean
    currentUserPlan: any
    isMediaKind : boolean
    isApiCalled: boolean
    isGuest: boolean,
    movieLoading: boolean,
    subtitleDetails: any
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class MovieDetailsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiGetMovieDetailsCallId: any = "";
    apiGetSimilarMoviesCallId: any = "";
    getWebSeriesAPICallId: string = "";
    getStreamingURLCallId: string = "";
    getUserCountCallId: string = "";
    createWatchHistoryAPICallId: string = "";
    getEpisodeAPICallId: string = "";
    getPodcastEpisodesAPICallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            errorMsg: "",
            token: "",
            loading: false,
            imgIndex: null,
            contentDetails: null,
            similarMovies: null,
            contentId: null,
            playVideo: false,
            streamingURL: "",
            userCountError: false,
            assetID: "",
            seasonsData: null,
            isSubscribed: false,
            updatePlayer: false,
            title: '',
            playingSeason: null,
            seasonsFetched: false,
            isFetchWebSeries: true,
            playFromStart: false,
            updatePodcastParts: false,
            currentUserPlan: null,
            isMediaKind : false,
            isApiCalled :false,
            isGuest : false,
            movieLoading: false,
            subtitleDetails: []
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start    
        const contentId = this.props.navigation.getParam('id');
        const selectedUserDataJson = localStorage.getItem("selected_user_data");
        const selectedUserData = selectedUserDataJson ? JSON.parse(selectedUserDataJson) : null;
        const currentUserPlan = selectedUserData ? selectedUserData.plan?.data?.attributes : {};
        this.setState({ contentId, currentUserPlan }, () => {
            this.onInitialized();
        });
        // Customizable Area End
    }

    async componentWillUnmount() {
        localStorage.removeItem("continueWatch");
    }

    async componentDidUpdate(prevProps: any, prevState: any) {
        const { contentId } = prevState;
        const currentContentId = this.props?.navigation?.getParam('id');
        contentId !== null && (contentId !== currentContentId) && this.setState({ contentId: currentContentId }, () => {
            this.onInitialized();
            prevState.playVideo && this.setState({ updatePlayer: true })
            prevState.playVideo && window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            localStorage.removeItem("continueWatch");
        });
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && !responseJson?.errors) {
                if (responseJson?.data && responseJson?.data?.length === 0) {
                    this.setState({
                        errorMsg: "Data Not Found",
                        loading: false
                    });
                } else {
                    this.setState({ errorMsg: "", loading: false })
                    switch (apiRequestCallId) {
                        case this.apiGetMovieDetailsCallId:
                            this.handleMovieDetailsResponce(responseJson);
                            break;
                        case this.apiGetSimilarMoviesCallId:
                            this.handleSimilarMoviesResponce(responseJson);
                            break;
                        case this.getWebSeriesAPICallId:
                            this.handleWebSeriesResponce(responseJson);
                            break;
                        case this.getStreamingURLCallId:
                            this.handleGetStreamingResponce(responseJson);
                            break;
                        case this.getUserCountCallId:
                            this.handleGetUserCountResponce(responseJson);
                            break;
                        case this.createWatchHistoryAPICallId:
                            this.handleWatchHistoryResponce(responseJson);
                            break;
                        case this.getEpisodeAPICallId:
                            this.handleEpisodesResponce(responseJson);
                            break;
                        case this.getPodcastEpisodesAPICallId:
                            this.handlePodcastEpisodesResponce(responseJson);
                            break;
                        default:
                            break;
                    }
                }
            } else {
               this.handleErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    // handers
    onInitialized = async() => {
        this.getMoviewDetailsApi();
        this.getSimilarMoviesApi();
        let isGuest = await getStorageData("isGuestUser");
        if ((await isGuest) === "true") {
        this.setState({ isGuest: true });
        }
    }
    //I308102

    getMoviewDetailsApi = () => {
        const { contentId } = this.state;
        const continueWatch = localStorage.getItem("continueWatch") ? JSON.parse(localStorage.getItem("continueWatch") || "") : null;
        let backendUrl = `profile_id=${localStorage.getItem("selectedUser")}&web=true`;
        if(continueWatch && continueWatch?.web_series_id == contentId && continueWatch?.content_type === CONTENT_TYPES.WEBSERIES) {
            backendUrl = backendUrl + `&for_web_series=true&ep_asset_id=${continueWatch?.asset_id}`
        }
        this.setState({ loading: true, movieLoading: true })
        this.apiGetMovieDetailsCallId =
            sendAPIRequest(
                `${configJSON.getMovieDetailsById}/${contentId}?${backendUrl}`,
                {
                    headers: {
                        language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
                        token: localStorage.getItem('Token')
                    }
                },
            );
    }
    getSimilarMoviesApi = () => {
        const { contentId } = this.state;
        this.setState({ loading: true })
        this.apiGetSimilarMoviesCallId = sendAPIRequest(`${configJSON.getSimilarMoviesById}?id=${contentId}&profile_id=${localStorage.getItem("selectedUser")}`,
            { headers: { language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en", token: localStorage.getItem('Token') } },
        );
    }

    handleMovieDetailsResponce = (json: any) => {
        const contentDetails = { ...json?.data?.attributes, ...json?.meta?.like };
        const { isFetchWebSeries } = this.state;
        this.setState({ contentDetails, title: contentDetails.title ,isSubscribed: json?.meta?.subscription }, () => {
            if (contentDetails?.content_type == CONTENT_TYPES.WEBSERIES && isFetchWebSeries) {
                this.getWebSeriesDetails(contentDetails)
                contentDetails?.watch_history && this.getEpisodesByRelease(contentDetails?.id, contentDetails?.watch_history?.release_id);
            } else {
                this.setState({ seasonsFetched: true, movieLoading: false });
            }
            if (this.state.updatePlayer) {
                this.onPlayVideo({ assetId: this.state.contentDetails.asset_id })
                this.setState({ updatePlayer: false })
            }
        });
        this.setState({ loading: false});
    }

    handleSimilarMoviesResponce = (json: any) => {
        const similarMovies = (json?.similar_contents || []).map((movie: any) =>
            movie?.content?.data);
        this.setState({ loading: false })
        this.setState({ similarMovies });
    }

    handleGetStreamingResponce = (json: any) => {
       this.setState({ loading: false });
       if(json?.streaming_urls){
        const {streaming_urls, subtitle_details} = json
        const { streamingPaths } = streaming_urls;
        this.setState({ subtitleDetails: subtitle_details })        
        let isMediaKind : boolean = !streaming_urls.hasOwnProperty('hls_asset_id')
        const baseURL = isMediaKind ? 'https://ep-default-vod-mediakind.eastus.streaming.mediakind.com' :'https://vodemedia-usea.streaming.media.azure.net';
        const streamingType = "Dash";
        const actualPath = (streamingPaths || []).filter((element: any) => element.streamingProtocol == streamingType);
        if (actualPath?.length > 0) {
            this.setState({ isMediaKind ,streamingURL: `${baseURL}${actualPath[0].paths[0]}` , isApiCalled:false})
        } else {
            this.setState({ playVideo: false, streamingURL: '' });           
            toast.error(this.props?.t("movieDetails.NoSreamUrlFound"));
        }
       }else{
        if(!this.state.isApiCalled){
                this.setState({isApiCalled: true},()=>{
                    this.getmediaKindStreamingURLApi(this.state.assetID)
                })
            }                    
        
       }
    }

    handleGetUserCountResponce = (json: any) => {
        this.setState({ loading: false });
        if ((parseInt(json.screen_count) > parseInt(json.player_count)) || this.state.isGuest) {
            this.setState({ playVideo: true })
            this.getStreamingURLApi(this.state.assetID);

        } else {
            this.setState({ userCountError: true })
        }
    }

    onClosePlayer = () => {
        this.setState({ playVideo: false, assetID: "", streamingURL: "" });
    }

    onPlayVideo = (data: any) => {
        const { title, assetId, playFromStart , episode_watch_history , isGuestSeries} = data;
        const { isSubscribed, seasonsData } = this.state;
        let subscription = isGuestSeries ? isGuestSeries : isSubscribed
        const updatedAssetId = (!isSubscribed && seasonsData?.length > 0) ? seasonsData[0].attributes.asset_id : assetId;
        if ((updatedAssetId == "" || updatedAssetId == undefined || updatedAssetId == null) || (!subscription )) {
            this.setState({ playVideo: false })
            this.handleSubscriptionClick()
        } else {
            episode_watch_history && this.setState((prevState) => ({ contentDetails: { ...prevState.contentDetails, watch_history: episode_watch_history} }));
            title && this.setState({ title });
            this.setState({ assetID: updatedAssetId, playFromStart }, () => this.getUserCount());
        }
    }

    getStreamingURLApi = (assetId: any) => {
        this.setState({ loading: true });
        this.getStreamingURLCallId =
            sendAPIRequest(
                `bx_block_content_management/contents/get_streaming_url?assetId=${assetId}`,
                {
                    headers: {
                        language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
                        token: localStorage.getItem('Token')
                    }
                },
            );
    }

    getmediaKindStreamingURLApi = (assetId: any) => {
        this.setState({ loading: true });
        this.getStreamingURLCallId =
            sendAPIRequest(
                configJSON.getMKStreamingUrl + assetId,
                {
                    headers: {
                        language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
                        token: localStorage.getItem('Token')
                    }
                },
            );
    }

    handleSubscriptionClick = () => {
        const navigationMes: Message = new Message(
            getName(MessageEnum.NavigationMessage)
          );
         navigationMes.addData(
            getName(MessageEnum.NavigationTargetMessage),"Perks")
          navigationMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
         this.send(navigationMes);
    }

    getUserCount = () => {
        this.setState({ loading: true });
        this.getUserCountCallId =
            sendAPIRequest(
                configJSON.getUserCount,
                {
                    headers: {
                        language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
                        token: localStorage.getItem('Token')
                    }
                },
            );
    }

    createWatchHistory = (asset_id: any, is_start: any, is_completed: any, duration: any, watching_duration: any) => {
        let string: any = this.state.contentDetails?.content_type === "WebSeries" ? '&for_web_series=true' : ''
        this.createWatchHistoryAPICallId = sendAPIRequest(`${configJSON.createWatchHistoryAPIEndPoints}?profile_id=${localStorage.getItem("selectedUser")}&is_start=${is_start}&is_completed=${is_completed}&duration=${duration}&watching_duration=${watching_duration}&asset_id=${asset_id}` + string,
            { headers: { language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en", token: localStorage.getItem('Token') }, method: "POST" },
        );
    }

    handleWatchHistoryResponce = (json: any) => {
        if (json?.data) {
            this.setState((prevState) => ({ contentDetails: { ...prevState.contentDetails, watch_history: json?.data?.attributes } }));
        }
    }

    updateState = (responceData: any) => {
        const { like, updatePodcastParts } = responceData;
        this.setState({ updatePodcastParts });
        if (like) {
            const { contentDetails } = this.state;
            this.setState({ contentDetails: { ...contentDetails, like } })
        }
    }

    onClickNext = (data: any) => {
        const { asset_id, title } = data;
        this.setState({ assetID: asset_id, title }, () => this.getUserCount());
    }

    onClickNextPart = (data:any) =>{
        this.props.navigation.navigate(`MovieDetails`, { id: data.id });
    }

    // Seasons API
    getWebSeriesDetails = (contentDetails: any) => {
        this.getWebSeriesAPICallId = sendAPIRequest(
            `${configJSON.webSeriesAPIEndPoints}?id=${contentDetails?.id}&profile_id=${localStorage.getItem("selectedUser")}`,
            {
                headers: {
                    token: localStorage.getItem('Token'),
                    language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
                }
            },
        );
    }

    handleWebSeriesResponce = (json: any) => {
        if (json && !json?.errors && json.data) {
            const data = { ...json?.data?.attributes, ...json?.meta };
            const seasonsData = { data: data?.first_episode?.data, meta: json?.meta, releases: data?.releases }
            if (data?.releases && data?.releases.length > 0) {
                const { contentDetails } = this.state;
                const webSeriesId = contentDetails?.watch_history ? contentDetails?.watch_history?.release_id : data?.releases[0].id;
                this.setState({ playingSeason: webSeriesId });
            }
            this.setState({ seasonsData, seasonsFetched: true, movieLoading: false });
        } else if (json && json.errors) {
            this.setState({
                errorMsg: json.errors,
                seasonsFetched: true
            });
        }

    }

    getEpisodesByRelease = (webSeriesId: any, releaseId: any, isPlayNextSeason = false) => {
        this.setState({ playingSeason: releaseId, isFetchWebSeries: !isPlayNextSeason });
        this.getEpisodeAPICallId = sendAPIRequest(
            `${configJSON.seasonsAPIEndPoints}?web_series_id=${webSeriesId}&release_id=${releaseId}&profile_id=${localStorage.getItem("selectedUser")}`,
            {
                headers: {
                    token: localStorage.getItem('Token'),
                    language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
                }
            },
        );
    }

    handleEpisodesResponce = (json: any) => {
        if (json && !json?.errors && json?.data && json?.data?.length > 0) {
            this.setState((prevState) => ({ seasonsData: { ...prevState.seasonsData, ...json } }));
            const { isFetchWebSeries } = this.state;
            if (!isFetchWebSeries) {
                this.onPlayVideo({ assetId: json?.data[0].attributes?.asset_id, title: json?.data[0].attributes?.title });
            }
        } else if (json?.errors) {
            this.setState({
                errorMsg: json.errors
            });
        }
    }

    getPodcastsEpisodes = (id: any) => {
        this.getPodcastEpisodesAPICallId = sendAPIRequest(`${configJSON.getpodcastEpisodes}?id=${id}`,
            {
                headers: {
                    token: localStorage.getItem('Token'),
                    language: localStorage.getItem("i18nextLng")
                }
            },

        );
    }
    handlePodcastEpisodesResponce = (json: any) => {
        if (json && !json?.errors && json?.data) {
            this.setState({ seasonsData: json, loading: false });
        }
        else if (json && json.errors) {
            this.setState({
                errorMsg: json.errors,
                loading: false
            });
        }
    }
    getData(guestMessage: string , upgradeMessage: string){
        return this.state.isGuest ? guestMessage : upgradeMessage;
    }
    handleErrorResponse = (errorReponse: any) => {
        if (errorReponse === undefined) {
            this.setState({
                errorMsg: "Something went wrong",
                loading: false
            });
        } else {
            this.setState({
                errorMsg: errorReponse,
                loading: false
            });
        }
    }

    // Customizable Area End

}