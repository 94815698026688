import React from "react";
// Customizable Area Start
import {  Box, Container, withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import "./Dashboard.css"
// Customizable Area End
import WebSeriesController from "./WebSeriesController.web";
import MoviesSlider from "./MoviesSlider.web";
import MovieSwiper from "./Swiper.web";
import MovieSwiperSkelton from "./Swiperskelton.web";
export class WebSeries extends WebSeriesController {
  constructor(props: any) {
    super(props)
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start



  removeChildBullets = () => {
    let bulletd: any = document.getElementsByClassName('swiper-pagination')[0];
    bulletd.childNodes[0].style.display = 'none';
  }
  navigate = async (id: any) => {
    sessionStorage.setItem("moviesData", id)
    this.props.navigation.navigate('Dashboard');
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, t }: any = this.props;
    const {moviesData,getContinueWatchData, moviesLoading, bandData, imgIndex } = this.state;

    return (
      <>
        <Container maxWidth={false} className={`${classes.dashboardContainer} dashBoard_slider`} disableGutters>
          {moviesLoading ? <MovieSwiperSkelton /> : moviesData?.length !== 0 && <MovieSwiper moviesData={moviesData} navHandler={this.navHandler} imgIndex={imgIndex} />}

        </Container>
        {getContinueWatchData?.length > 0 && <Box className={classes.SliderWrapper}>
          <MoviesSlider
            navHandler={this.navHandler}
            slidesToShow={10}
            slidesToScroll={5}
            sliderTitle={t("movieDetails.continueWatching")}
            isContinueWatching={true}
            similarMovies={getContinueWatchData}
          />
        </Box>}
        {bandData?.map((item) => {
          return (
            <Box key={item.id} className={classes.SliderWrapper}>
              {item?.bend_contents?.data?.length > 0 && (
                <MoviesSlider
                  navHandler={this.navHandler}
                  slidesToShow={10}
                  slidesToScroll={5}
                  sliderTitle={item?.name}
                  similarMovies={item?.bend_contents?.data}
                />)}
            </Box>
          )
        })
        }

      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
  sliderContainer: {
    background: 'radial-gradient(rgba(22, 22, 29, 1) 0%, rgba(16, 16, 20, 1) 100%)',
    position: 'relative',
    top: 0,
  },
  DownloadImage: {
    width: "20px",
    height: "20px !important",
    margin: theme.direction === "rtl" ? "0 0 0 10px" : "0 10px 0 0",
    filter: localStorage.getItem("darkMode") == "true" ? "none" : 'invert(1)'
  },
  DownloadButton: {
    width: "150px",
    height: "45px",
    margin: theme.direction === "rtl" ? "0 0 0 10px" : "0 10px 0 0",
    backgroundColor: theme.palette.background.blackDark,
    border: "1px solid #979797",
    "&:hover": {
      backgroundColor: theme.palette.background.blackDark,
    },
    [theme.breakpoints.down("sm")]: {
      width: "140px",
    },
  },
  playButton: {
    width: "150px",
    height: "46px",
    backgroundColor: "rgba(234, 67, 93, 1)",
    opacity: 1,
    "&:hover": {
      opacity: 0.9,
      backgroundColor: "rgba(234, 67, 93, 1)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "140px",
    },
  },
  SliderWrapper: {
    margin: theme.direction === "rtl" ? '0 5% 0 0' : '0 0 0 5%'
  },
  Slider: {
    margin: 'auto',
    width: '100%',
    height: '75vh',
  },
  movieNames: {
    color: '#fff',
    position: 'absolute',
    top: 170,
    textAlign: 'center',
    fontSize: 20,
  },
  dashboardContainer: {
    margin: '0px',
    padding: '0px',
    overflowX: 'hidden',
    boxSizing: 'border-box',
  },
  arabicButtons: {
    position: "absolute",
    bottom: "30px",
    left: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("sm")]: {
      bottom: "20px",
    },
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#fff',
    margin: '10px 5vw',
    background: 'transparent',
    boxShadow: 'none'
  },
  leftHeader: {
    display: 'flex',
  },
  rightHeader: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  play: {
    width: '70px',
    height: '70px',
    position: 'relative',
    top: -120,
    right: -80,
    [theme.breakpoints.only("xs")]: {
      width: '50px',
      height: '50px',
      top: -105,
      right: -90,
    },
  },
  backgroundImage_div3: {
    maxWidth: "100%",
    width: "100vw",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    background: 'radial-gradient(rgba(22, 22, 29, 1) 0%, rgba(16, 16, 20, 1) 100%)',
    [theme.breakpoints.only("xs")]: {
      padding: "27px 10px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "27px 10px",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "32px 10px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "32px 10px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "32px 10px",
    },
  },

  typo: {
    color: '#fff',
    [theme.breakpoints.only("xs")]: {
      fontSize: '17px',
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: '25px',
    },
    [theme.breakpoints.only("md")]: {
      fontSize: '27px',
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: '30px',
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: '30px',
    },
  },

  arrowLeft: {
    border: '2px solid #fff',
    cursor: 'pointer',
    background: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  arrowRight: {
    border: '2px solid #fff',
    cursor: 'pointer',
    background: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  arrows: {
    [theme.breakpoints.only("xs")]: {
      fontSize: '15px',
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: '22px',
    },
    [theme.breakpoints.only("md")]: {
      fontSize: '25px',
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: '30px',
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: '30px',
    },
  },
  arrowsGrid: {
    width: '5%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    right: 50,
    [theme.breakpoints.only("xs")]: {
      position: 'relative',
      right: 0,
      width: '15%',
    },
    [theme.breakpoints.only("sm")]: {
      position: 'relative',
      right: 0,
      width: '10%',
    },
    [theme.breakpoints.only("md")]: {
      position: 'relative',
      right: 0,
      width: '10%',
    },
    [theme.breakpoints.only("lg")]: {

    },
    [theme.breakpoints.up("lg")]: {

    },
  },

})
export default withStyles(styles)(withTranslation()(WebSeries))
// Customizable Area End
